"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetLocaleDocument = exports.ChatMessageRole = void 0;
var ChatMessageRole;
(function (ChatMessageRole) {
    ChatMessageRole["Assistant"] = "assistant";
    ChatMessageRole["Function"] = "function";
    ChatMessageRole["System"] = "system";
    ChatMessageRole["User"] = "user";
})(ChatMessageRole = exports.ChatMessageRole || (exports.ChatMessageRole = {}));
exports.GetLocaleDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "getLocale" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "locale" }, "directives": [{ "kind": "Directive", "name": { "kind": "Name", "value": "client" } }] }] } }] };
