import React, { ReactElement } from 'react';
import type { ReactNode } from 'react';
import { Page as TemplatePage, ContentArea, Main, Body, Header } from '@cvent/carina/components/Templates';
import { TopNavigation } from './TopNavigation';

export function BasePage({ children }: { children: ReactNode }): ReactElement {
  return (
    <TemplatePage>
      <Main as="div">
        <Header>
          <TopNavigation />
        </Header>
        <Body>
          <ContentArea>
            <div
              css={{
                flexGrow: 1,
                overflowY: 'auto'
              }}
            >
              {children}
            </div>
          </ContentArea>
        </Body>
      </Main>
    </TemplatePage>
  );
}
